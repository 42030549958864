import React from "react"
import { graphql } from "gatsby"
import "./login-hero.module.scss"

import { HeroContent } from "components/hero/HeroContent"
import { LoginForm } from "components/login/LoginForm"
import { Banner } from "../_types/hero-types"
import { BannerWrapper } from "../BannerWrapper/BannerWrapper"
import { HeroContentBaseClassName } from "../_types/hero-classes-types"
import { useBackgroundImage } from "hooks/useBackgroundImage"

interface LoginBannerProps {
  banner: Banner
}

export const LoginHero = ({ banner }: LoginBannerProps) => {
  const { background_image, heading, sub_heading, secondary_image } = banner

  const secondaryImage = secondary_image?.value[0],
    backgroundImageUrl = background_image?.value[0]?.url,
    hasBackgroundImage = !!backgroundImageUrl,
    wrapperClassName = hasBackgroundImage
      ? `fr-login-hero--with-background-image`
      : `fr-login-hero--with-default-background-color`,
    bannerContentBaseClassName = hasBackgroundImage
      ? (`fr-login-hero__banner-content--with-image` as HeroContentBaseClassName)
      : (`fr-login-hero__banner-content` as HeroContentBaseClassName)

  const formMobileBackgroundImageCSSVariable = useBackgroundImage({
    backgroundImageUrl,
    variable: "hero",
  })

  return (
    <div className={"fr-login-hero-container"}>
      <BannerWrapper
        contentClassName={bannerContentBaseClassName}
        prefix="fr-login-hero"
        wrapperClassName={wrapperClassName}
        containerSize="large"
        backgroundImageUrl={backgroundImageUrl}
      >
        <HeroContent
          baseClassName="fr-login-hero"
          heading={heading?.value}
          subHeading={sub_heading}
          secondaryImage={secondaryImage}
        />
      </BannerWrapper>
      <div className="fr-login-hero__form-background-wrapper">
        <div className="fr-container fr-container--large fr-login-hero__form-container">
          <LoginForm />
        </div>
        {backgroundImageUrl && (
          <div
            className={`fr-login-hero__background-image fr-login-hero__background-image--mobile`}
            style={formMobileBackgroundImageCSSVariable}
          />
        )}
      </div>
    </div>
  )
}

export const query = graphql`
  fragment LoginBannerFragment on kontent_item_hero_banner {
    id
    elements {
      heading {
        value
      }
      sub_heading {
        value
        links {
          link_id
          url_slug
        }
      }
      background_image {
        value {
          ...KenticoAssetElementFragment
        }
      }
      secondary_image {
        value {
          ...KenticoAssetElementFragment
        }
      }
    }
  }
`
